/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import _ from 'lodash';

export function Row({ className, ...rest }) {
	return (
		<Flex
			className={`flex row ${className || ''}`}
			baseCss={[flex, row]}
			rightClass={rowRight}
			leftClass={rowLeft}
			{...rest}
		/>
	);
}

export function Column({ className, ...rest }) {
	return (
		<Flex
			className={`flex column ${className || ''}`}
			baseCss={[flex, column]}
			rightClass={columnRight}
			leftClass={columnLeft}
			{...rest}
		/>
	);
}

function Flex({
	children,
	grow,
	noBasis,
	center,
	stretch,
	left,
	right,
	bottom,
	spaceBetween,
	spaceAround,
	className,
	end,
	top,
	start,
	style,
	baseCss,
	rightClass,
	leftClass,
	...props
}) {
	let css = baseCss || [];

	let other_classes = _.compact([
		center ? css_center : null,

		spaceBetween ? css_space_between : null,
		bottom || end ? align_end : null,
		stretch ? css_stretch : null,
		left ? leftClass : null,
		grow ? css_grow : null,
		noBasis ? css_no_basis : null,
		spaceAround ? css_space_around : null,
		start || top ? align_start : null,
		right ? rightClass : null
	]);

	return (
		<div
			className={className}
			css={[...css, ...other_classes]}
			style={{ ...style }}
			{...props}
		>
			{children}
		</div>
	);
}

const flex = css`
	display: flex;
`;

const column = css`
	flex-direction: column;
`;

const row = css`
	flex-direction: row;
`;

const css_grow = css`
	flex-grow: 1;
`;

const align_end = css`
	align-items: flex-end;
`;

const align_start = css`
	align-items: flex-start;
`;

const css_center = css`
	justify-content: center;
	align-items: center;
`;

const columnLeft = css`
	align-items: flex-start;
`;

const columnRight = css`
	align-items: flex-end;
`;

const rowLeft = css`
	justify-content: flex-start;
`;

const rowRight = css`
	justify-content: flex-end;
`;

const css_space_between = css`
	justify-content: space-between;
`;

const css_space_around = css`
	justify-content: space-around;
`;

const css_stretch = css`
	align-items: stretch;
`;

const css_no_basis = css`
	flex-basis: 0;
`;
