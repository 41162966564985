/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { animated } from 'react-spring';

export default function LoadingSpinner({ style, size = 20, className }) {
  const css_spinner = css`
    display: inline-block;
    position: relative;
    width: ${size}px;
    height: ${size}px;

    & div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: ${size * 0.8}px;
      height: ${size * 0.8}px;
      margin: ${size * 0.1}px;
      border: ${size * 0.1}px solid #fff;

      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
    }
    & div:nth-of-type(1) {
      animation-delay: -0.45s;
    }
    & div:nth-of-type(2) {
      animation-delay: -0.3s;
    }
    & div:nth-of-type(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;
  return (
    <animated.div css={css_spinner} style={style} className={className}>
      <div />
      <div />
      <div />
      <div />
    </animated.div>
  );
}
