/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useState } from "react";
import Result from "../Result";

let flyoutStyles = css`
  position: fixed;
  right: 50px;

  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: end;
  transition: bottom 0.33s;
`;

let flyoutTabStyles = css`
  display: flex;
  align-items: center;
  background-color: white;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 20px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;

  cursor: pointer;
`;

let arrowStyle = css`
  height: 1em;
  padding-right: 8px;
`;

let resultContentStyles = css`
  height: 750px;
  width: 50vw;
  border: 1px solid black;
  border-top-left-radius: 2px;
  background: white;
  overflow: auto;
`;

export default function ResultsFlyout(props) {
  let [isOpen, setIsOpen] = useState(false);
  let Arrow = isOpen ? ArrowDown : ArrowUp;
  return (
    <div css={flyoutStyles} style={{ bottom: isOpen ? "0px" : "-750px" }}>
      <span css={flyoutTabStyles} onClick={() => setIsOpen(!isOpen)}>
        <Arrow />
        {isOpen ? "Close" : "Score Preview"}
      </span>
      <ResultPreviewContent></ResultPreviewContent>
    </div>
  );
}

function ArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      css={arrowStyle}
    >
      <path d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
    </svg>
  );
}

function ArrowUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      css={arrowStyle}
    >
      <path d="M9.39 265.4l127.1-128C143.6 131.1 151.8 128 160 128s16.38 3.125 22.63 9.375l127.1 128c9.156 9.156 11.9 22.91 6.943 34.88S300.9 320 287.1 320H32.01c-12.94 0-24.62-7.781-29.58-19.75S.2333 274.5 9.39 265.4z" />
    </svg>
  );
}

function ResultPreviewContent() {
  return (
    <div css={resultContentStyles}>
      <div
        css={css`
          padding: 20px;
        `}
      >
        <Result isFlyout={true} />
      </div>
    </div>
  );
}
