import React from 'react';

export default function generateContext(name) {
	const context = React.createContext();
	class ContextProvider extends React.Component {
		state = {};
		render() {
			return (
				<context.Provider value={this.state}>
					{this.props.children}
				</context.Provider>
			);
		}
	}

	let highOrderConsumer = Component => props => (
		<context.Consumer>
			{context => <Component {...props} {...{ [name]: context }} />}
		</context.Consumer>
	);

	return [context, ContextProvider, highOrderConsumer];
}
