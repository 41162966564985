/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Row } from './Flex.js';
import Flatpickr from 'react-flatpickr';
const css_input = css`
	flex-grow: 1;
	padding: 0px 10px;
`;

const css_input_container = css`
	height: 38px;
`;

export default function Input({
	className,
	onChange,
	placeholder,
	type = 'text',
	suffix,
	name,
	value
}) {
	let Component = (
		<input
			css={css_input}
			type={type}
			className={className}
			onChange={onChange}
			placeholder={placeholder}
			value={value}
			name={name}
		/>
	);
	return (
		<Row grow css={css_input_container}>
			{Component}
			{suffix}
		</Row>
	);
}
