/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import Table from "../common/Table";
import { useContext, useEffect, useState } from "react";
import ParticipantContext from "../contexts/ParticipantContext.js";
import { Column, Row } from "../common/Flex";
import Button from "../common/Button";
import Input from "../common/Input";
import _ from "lodash";
import { Link, useHistory } from "react-router-dom";
import { H1, H2 } from "../common/Label.js";
import LoadingSpinner from "../common/LoadingSpinner.js";

const css_title = css`
  color: white;
  padding: 20px;
`;

const css_input_container = css`
  padding: 20px;
`;

const css_panel = css`
  animation: fade-in 0.5s;
`;

const css_header_row = css`
  padding: 0px 20px;
`;

function ParticipantList(props) {
  const History = useHistory();
  const Participants = useContext(ParticipantContext);
  const [state, setState] = useState({
    participants: [],
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchParticipants();
    async function fetchParticipants() {
      let result = await Participants.dispatch({
        type: "FETCH_PARTICIPANTS",
      });

      setState({ participants: result });
      setIsLoading(false);
    }
  }, []);

  const filterChange = (e) => {
    Participants.dispatch({
      type: "SET_FILTER",
      value: e.target.value,
    });
  };

  let filteredParticipants = filterParticipants(
    Participants.debounced_filter,
    state.participants || []
  );

  const participantColumns = [
    { label: "Client ID", accessor: (d) => d.uid },
    { label: "State ID / #", accessor: (d) => d.state_identifier },
    {
      label: "Name",
      accessor: (d) => (
        <Link to={`/participants/${d.uid}`}>
          {d.first_name} {d.last_name}
        </Link>
      ),
    },
    {
      label: "Address",
      accessor: (d) => `${d.address} ${d.address2 || ""}`,
    },
    { label: "City", accessor: (d) => d.city },
    { label: "State", accessor: (d) => d.state },
    { label: "DOB", accessor: (d) => d.date_of_birth },
    {
      label: "Cases",
      accessor: (d) => (
        <Link to={`/participants/${d.uid}`}>{d.cases.length}</Link>
      ),
    },
    {
      label: "Actions",
      accessor: (d) => <Link to={`/participants/${d.uid}/edit`}>Edit</Link>,
    },
  ];

  if (isLoading) {
    return (
      <Column center style={{ width: "100%" }}>
        <LoadingSpinner size={75} />
      </Column>
    );
  }
  return (
    <Column
      grow
      center
      style={{ justifyContent: "flex-start" }}
      css={css_panel}
    >
      <Column>
        <Row center spaceBetween css={css_header_row}>
          <H1 css={css_title}>All Participants</H1>
          <Column>
            <Button onClick={() => History.push("/participants/new")}>
              Add new participant
            </Button>
          </Column>
        </Row>
        <Row center css={css_input_container}>
          <H2 white style={{ paddingRight: "15px" }}>
            Search for:
          </H2>
          <Input
            value={Participants.filter}
            placeholder="Name, Address, State, ID, Date of birth..."
            onChange={filterChange}
          />
        </Row>
        <Table columns={participantColumns} data={filteredParticipants} />
      </Column>
    </Column>
  );
}

function filterParticipants(filter, participants) {
  participants = participants.map((p) => ({
    ...p,
    name: `${p.first_name} ${p.last_name}`,
    compound_state: `${p.address} ${p.address2} ${p.city} ${p.state}`,
  }));
  return _.filter(participants, (participant) =>
    _(participant)
      .values()
      .map((p) => {
        // console.log(p);

        if (!p || typeof p !== "string") {
          return false;
        }

        if (filter === "") {
          return true;
        }

        return p
          .toLowerCase()
          .replace(/\W/g, "")
          .includes(filter.toLowerCase().replace(/\W/g, ""));
      })
      .some()
  ).reverse();
}
export default ParticipantList;
