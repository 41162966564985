import React, { useContext } from 'react';
import { Row } from '../common/Flex.js';
import AssessmentTableOfContents from './Assessment/AssessmentTableOfContents.js';
import QuestionPanel from './Assessment/QuestionPanel.js';
import Loader from '../common/Loader';
import { AssessmentContext } from '../contexts/AssessmentContext.js';
import ResultsFlyout from './Assessment/ResultsFlyout.js';

export default function Assessment(props) {
	let Assessment = useContext(AssessmentContext);

	if (!Assessment.isDocLoaded) {
		return <Row center grow>
			<Loader />
		</Row>
	}

	return (
		<Row grow>
			<AssessmentTableOfContents />
			<QuestionPanel />
			<ResultsFlyout/>
		</Row>
	);
}
