import generateContext from './generateContext';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { withParticipant } from './ParticipantContext.js';
import Requisition from '../common/RequisitionLite.js';

import setStateAsync from '../common/setStateAsync.js';

let [AnswerContext, ProviderBase, withAnswer] = generateContext('answer');
let api =
	process.env.NODE_ENV === 'development'
		? 'https://test2.vtceitool.org/api'
		: '/api';

const AnswerProvider = withRouter(
	withParticipant(
		class extends ProviderBase {
			updateCase = this.updateCase.bind(this);
			saveAnswers = this.saveAnswers.bind(this);

			state = {
				activeCase: '',
				activeAssessment: '',
				assessment_type: '',
				answers: {},
				createNewCase: this.createNewCase.bind(this),
				updateAnswer: this.updateAnswer.bind(this),
				save: this.save.bind(this),
				createNewAssessment: this.createNewAssessment.bind(this),
				setActiveCase: this.setActiveCase.bind(this),
				setActiveAssessment: this.setActiveAssessment.bind(this),
				finish: this.finish.bind(this),
				loadAnswers: this.loadAnswers.bind(this),
			};

			async createNewCase(participant_id, assessment_type) {
				const endpoint = '/case/create';
				await setStateAsync(this, {
					activeAssessment: '',
					assessment_type: assessment_type,
					answers: { TYPE: { qanswer: assessment_type } },
				});
				let result = await Requisition(`${api}${endpoint}`, {
					method: 'POST',
					body: JSON.stringify({ participant_id }),
				})
					.then((res) => res.json())
					.catch((err) => console.log(err));
				result = result.data;
				let id = result.case_id;
				this.setActiveCase(id);
				this.createNewAssessment(id, assessment_type, participant_id);
			}

			setActiveCase(id) {
				this.setState({ activeCase: id });
				window.localStorage.setItem('case', id);
			}

			componentWillMount() {
				this.loadCase();
				this.loadAssessment();
			}

			loadCase() {
				let activeCase = window.localStorage.getItem('case');
				if (activeCase) {
					this.setState({ activeCase });
				}
			}

			async updateCase(id) {
				if (id) {
					this.setState({ activeCase: id });
				}
				id = id || this.state.activeCase;
				let endpoint = `/case/${this.state.activeCase}`;
				let is_not_empty = _.some(
					[
						_.get(this, 'state.answers.A5.qanswer', ''),
						_.get(this, 'state.answers.A6.qanswer', ''),
						_.get(this, 'state.answers.A7.qanswer', ''),
						_.get(this, 'state.answers.A1.qanswer', ''),
					],
					(q) => q !== ''
				);
				if (is_not_empty) {
					let body = {
						case_number: _.get(
							this,
							'state.answers.A5.qanswer',
							''
						),
						arrest_date: _.get(
							this,
							'state.answers.A6.qanswer',
							''
						),
						arrest_charge: _.get(
							this,
							'state.answers.A7.qanswer',
							''
						),
						interviewer_initials: _.get(
							this,
							'state.answers.A1.qanswer',
							''
						),
					};

					await Requisition(`${api}${endpoint}`, {
						method: 'PUT',
						body: JSON.stringify(body),
					}).catch((err) => console.log(err));
				} else {
					console.log('skipping save as result was empty');
				}
			}

			async createNewAssessment(case_id, type, participant_id) {
				let participant = this.props.participant.get_participant(
					participant_id
				);
				await setStateAsync(this, {
					activeAssessment: '',
					assessment_type: type,
					answers: { TYPE: { qanswer: type } },
				});
				// this.setActiveAssessment(null, type);
				const onReadyToSave = async function () {
					const endpoint = '/assessment/create';

					let result = await Requisition(`${api}${endpoint}`, {
						method: 'POST',
						body: JSON.stringify({ case_id, type }),
					})
						.then((res) => res.json())
						.catch((err) => console.log(err));
					result = result.data;
					let id = result.assessment_id;
					console.log('resulting id', id);
					await setStateAsync(this, {
						answers: {
							...this.state.answers,
							TYPE: { qanswer: type },
							A2: {
								qanswer: `${participant.first_name} ${participant.last_name}`,
							},
							A4: { qanswer: participant.date_of_birth },
						},
					});
					this.saveAnswers(id);

					this.setActiveAssessment(id, type);
					return id;
				};
				this.onReadyToSave = onReadyToSave.bind(this);
			}

			async setActiveAssessment(id, type) {
				console.log('setting active assessment', id);
				this.setState({ activeAssessment: id });
				if (type) {
					this.setState({ assessment_type: type });
				}
				let answers = { ...this.state.answerse };
				if (this.state.assessment_type) {
					answers.TYPE = {
						qanswer: this.state.assessment_type,
					};
				}
				await setStateAsync(this, {
					answers: answers,
				});

				window.localStorage.setItem('assessment', id);
				window.localStorage.setItem('assessment_type', type);
				this.loadAnswers(id);
			}

			loadAssessment() {
				let activeAssessment = window.localStorage.getItem(
					'assessment'
				);
				if (activeAssessment) {
					let assessment_type = window.localStorage.getItem(
						'assessment_type'
					);
					this.setState({ activeAssessment, assessment_type });
					this.loadAnswers(activeAssessment);
				}
			}

			async save() {
				return await this.saveAnswers(this.state.activeAssessment);
			}

			updateAnswer(id, answer, score) {
				setStateAsync(this, {
					answers: {
						...this.state.answers,
						[id]: new Answer({
							qanswer: answer,
							qweight: score || 0,
						}).toJSON(),
					},
				});
			}

			async saveAnswers(id) {
				if (!id || id === 'null') {
					if (
						this.state.answers.A1 &&
						this.state.answers.A3 &&
						this.state.answers.A5 &&
						this.state.answers.A6
					) {
						id = await this.onReadyToSave();
						console.log('created new', id);
						const endpoint = '/assessment';
						await Requisition(`${api}${endpoint}/${id}`, {
							method: 'POST',
							body: JSON.stringify({
								answers: this.state.answers,
							}),
						});

						this.updateCase();
						return true;
					} else {
						console.log(
							'not saving due to lack of answers',
							this.state.answers
						);
						return false;
					}
				} else {
					const endpoint = '/assessment';
					await Requisition(`${api}${endpoint}/${id}`, {
						method: 'POST',
						body: JSON.stringify({
							answers: this.state.answers,
						}),
					});
					this.updateCase();
					return true;
				}
			}

			async loadAnswers(id) {
				if (id) {
					this.setState({ answers: {} }, async () => {
						try {
							const endpoint = '/assessment';
							let load_result = await Requisition(
								`${api}${endpoint}/${id}`
							)
								.then((res) => res.json())

								.catch((err) => {
									throw new Error(err);
								});
							let data = load_result.data;
							data = _.mapValues(data, (val) =>
								new Answer(val).toJSON()
							);
							let answers = { ...data };

							if (
								!answers.TYPE &&
								this.state.assessment_type &&
								this.state.assessment_type !== 'undefined'
							) {
								answers.TYPE = {
									qanswer: this.state.assessment_type,
								};
							}
							this.setState({
								answers: { ...this.state.answers, ...answers },
								assessment_type: answers.TYPE.qanswer,
							});
						} catch (e) {
							console.log(e);
						}
					});
				}
			}

			finish() {
				console.log('the. end.', this.state);
				this.props.history.push(
					`/score/${this.state.activeAssessment}`
				);
			}
		}
	)
);

export { AnswerContext, withAnswer, AnswerProvider };

class Answer {
	constructor(obj) {
		this.section = obj.section || '';
		this.answer = obj.qanswer || '';
		this.weight = obj.qweight || '';
		this.type = obj.qmaxweight || '';
	}

	toJSON() {
		return {
			section: this.section,
			qanswer: this.answer,
			qweight: this.weight,
			qmaxweight: this.type,
		};
	}
}
