/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useContext, useState } from "react";
import ParticipantContext from "../contexts/ParticipantContext.js";
import ParticipantInfoRow from "./ParticipantEditor/ParticipantInfoRow.js";
import { H1 } from "../common/Label";
import { Column, Row } from "../common/Flex.js";
import Button from "../common/Button.js";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const css_container = css`
  width: 100%;
  justify-content: start;
  margin-top: 20px;
  padding: 0 80px 20px;
  color: white;
`;

const css_h1 = css`
  text-align: start;
`;

const css_button = css`
  margin: 20px;
`;

const css_form_container = css`
  background: white;
  color: #333;
  padding: 20px 0px;
  background: #ffffff;
  border: 2px solid #979797;
  box-shadow: 0px 0px 29px rgba(0, 0, 0, 0.32);
  border-radius: 6px;
  overflow: auto;
`;

export default function ParticipantCreator(props) {
  const Participant = useContext(ParticipantContext);
  const History = useHistory();

  const participant_info = [
    {
      label: "First name",
      type: "text",
      required: true,
      key: "first_name",
    },
    { label: "Last name", type: "text", required: true, key: "last_name" },
    {
      label: "Date of birth",
      type: "date",
      required: true,
      key: "date_of_birth",
    },
    { label: "Address", type: "text", required: true, key: "address" },
    { label: "Address 2", type: "text", required: false, key: "address2" },
    { label: "City", type: "text", required: true, key: "city" },
    { label: "State", type: "state", required: true, key: "state" },
    { label: "Zip", type: "text", key: "zip", required: false },
    {
      label: "State ID number",
      type: "text",
      key: "state_identifier",
      required: false
    },

    // {
    // 	label: 'Case number',
    // 	type: 'text',
    // 	required: false,
    // 	key: 'case_number'
    // },
    // {
    // 	label: 'Arrest date',
    // 	type: 'date',
    // 	required: false,
    // 	key: 'arrest_date'
    // },
    // {
    // 	label: 'Arrest charge',
    // 	type: 'text',
    // 	required: false,
    // 	key: 'arrest_charge'
    // },
    // { label: "Charge severity", type: "text", required: false, key: "charge_severity" },
    // {
    // 	label: 'Program name',
    // 	type: 'text',
    // 	required: false,
    // 	key: 'program_name'
    // }
  ];

  const [state, setState] = useState({
    participant: participant_info.reduce((accum, val) => {
      accum[val.key] = "";
      return accum;
    }, {}),
  });

  const [err, setError] = useState([]);

  function validate() {
    let errors = _.compact(
      _.map(state.participant, (val, key) => {
        if (!['address2', 'zip', 'state_identifier'].includes(key) && val === "") {
          return key.replace(/_/g, " ");
        }

        return "";
      })
    );
    let is_error = _.some(errors);
    if (is_error) {
      setError(errors);
      throw new Error(errors);
    } else {
      setError([]);
    }
  }
  console.log(err);

  let errDisplay;
  if (err.length) {
    errDisplay = (
      <span style={{ color: "#ff4444" }}>
        The following required fields were missing: {err.join(", ")}
      </span>
    );
  }
  return (
    <Column css={css_container} grow center>
      <Column style={{ width: "100%" }}>
        <Row center spaceBetween grow>
          <H1 css={css_h1}>Add New Participant</H1>

          <Row>
            <Button
              css={css_button}
              onClick={async () => {
                try {
                  validate();
                  await Participant.dispatch({
                    type: "CREATE",
                    payload: state.participant,
                  });

                  History.push("/participants");
                } catch (e) { }
              }}
            >
              Save and Return
            </Button>
            <Button css={css_button} onClick={() => History.push("/")}>
              Cancel
            </Button>
          </Row>
        </Row>

        <Column css={css_form_container} center>
          {errDisplay}
          <Column end>
            {participant_info.map((p, index) => (
              <ParticipantInfoRow
                onChange={(e) =>
                  setState({
                    participant: {
                      ...state.participant,
                      [p.key]: e.target.value,
                    },
                  })
                }
                participant={state.participant}
                {...p}
                key={p.key || index}
                k={p.key}
              />
            ))}
          </Column>
        </Column>
      </Column>
    </Column>
  );
}
