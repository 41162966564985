/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Column, Row } from "../common/Flex";
import Input from "../common/Input";

export default function Help() {
  return (
    <Column center>
      <LoginCard />
    </Column>
  );
}

const css_container = css`
  width: 100%;
  background-image: url("/steps.png");
  background-size: cover;
  position: relative;
`;

const css_shade_blue = css`
  background: #004fa2;
  opacity: 0.6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
`;

const css_card = css`
  background: #ffffff;
  border: 2px solid #979797;
  box-shadow: 0px 0px 29px rgba(0, 0, 0, 0.32);
  border-radius: 6px;
  padding: 30px;
  z-index: 1;
`;

const css_h2 = css`
  font-size: 20px;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
`;

const css_h1 = css`
  font-size: 48px;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
`;

const css_input_container = css`
  padding-top: 20px;
`;

const css_extra_action_container = css`
  padding-top: 48px;
`;

const css_extra_action = css`
  &:first-of-type {
    padding-right: 10px;
  }

  &:last-of-type {
    padding-left: 10px;
  }
`;

const css_input = css`
  margin-bottom: 10px;
`;

const css_error_message = css`
  color: red;
`;

function LoginCard(props) {
  return (
    <Column center style={{ width: "100vw" }}>
      <Column center css={css_card}>
        <Column>
          <h1 css={css_h1}>Help</h1>
        </Column>
        <Column>
          <p>
            If you have questions about the system or the assessment, please
            contact Dr. Jamie Vaske
          </p>
          <div>
            <div>Jamie Vaske</div>
            <a href="mailto:jvaske@email.wcu.edu">jvaske@email.wcu.edu</a>
            <div>828-227-3615</div>
          </div>
        </Column>
        <h2 css={css_input_container}>Additional help</h2>
        <Row center>
          <a href="/user-guide-4-2023.docx" style={{ paddingRight: "20px" }}>
            User Guide
          </a>
          <a href="/vets-q-a-document.pdf">VETS Q&A Document</a>
        </Row>
      </Column>
    </Column>
  );
}
