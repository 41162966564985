/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Row, Column } from './Flex.js';

const css_table = css`
	border-radius: 4px;
	margin: 20px;
	overflow: hidden;
`;

const css_column = css`
	flex-grow: 1;
`;

const css_column_header = css`
	font-weight: bold;
	background: #0a0a0a;
	border-bottom: 1px solid #979797;
	color: white;
	flex-grow: 1;
	height: 45px;
	font-family: 'Noto Sans', sans-serif;
`;

const css_cell_base = css`
	padding: 15px 25px;
	box-sizing: border-box;
	height: 90px;
`;

const css_odd_row = css`
	background: #ffffff;
`;

const css_even_row = css`
	background: #dfdfdf;
`;

const css_cell = css`
	border-top: 1px solid rgba(224, 224, 224, 1);
	overflow-y: auto;
	font-size: 1.15em;
	font-family: 'EB Garamond', serif;
`;

const css_placeholder = css`
	padding: 70px 30px;
	color: rgba(50, 50, 50, 0.8);
	font-size: 1em;
	background: #dfdfdf;
	margin: -20px 20px;
`;

export default function Table({
	data = [],
	columns = [],
	placeholder = 'Entries will appear here'
}) {
	return (
		<Column>
			<Row css={css_table}>
				{columns.map((c, index) => (
					<TableColumn key={c.label || index} data={data} {...c} />
				))}
			</Row>
			{data.length === 0 && (
				<Row center css={css_placeholder}>
					{placeholder}
				</Row>
			)}
		</Column>
	);
}

function TableColumn({ data, label, accessor }) {
	accessor = accessor || (d => d[label]);

	return (
		<Column css={css_column}>
			<ColumnHeader>{label}</ColumnHeader>
			{data.map((d, index) => (
				<TableCell
					key={index}
					css={index % 2 === 0 ? css_even_row : css_odd_row}
				>
					{accessor(d)}
				</TableCell>
			))}
		</Column>
	);
}

function ColumnHeader({ children }) {
	return <Row css={[css_cell_base, css_column_header]}>{children}</Row>;
}

function TableCell({ children, className }) {
	return (
		<Row left center css={[css_cell_base, css_cell]} className={className}>
			{children}
		</Row>
	);
}
