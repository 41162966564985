/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import { Column, Row } from "../common/Flex.js";
import Card from "../common/Card.js";
import Button from "../common/Button.js";
import { withRouter } from "react-router-dom";

const css_card_row = css``;

const css_landing_card = css`
  margin: 0px 20px;
  flex-grow: 1;
  padding: 20px;
  min-height: 250px;
  font-family: "EB Garamond", serif;

  &:first-of-type {
    margin-bottom: 10px;
  }
  &:last-of-type {
    margin-top: 10px;
  }

  &:only-child {
    margin: 0px 20px;
  }
`;

const css_h2 = css`
  font-family: "Noto Sans", sans-serif;
  font-size: 1.25em;
  font-weight: bold;
`;

const main_container = css`
  max-width: 1100px;
  margin: auto;
`;

const css_system_info = css`
  padding: 0px 20px;
`;

const css_system_info_card = css``;

function Landing(props) {
  return (
    <Column grow css={main_container}>
      <Row grow>
        <Column css={css_card_row} stretch grow spaceBetween>
          <Card css={css_landing_card} grow>
            <CardContent title="Create new participant">
              <Button onClick={() => props.history.push(`/participants/new`)}>
                Go
              </Button>
            </CardContent>
          </Card>
          <Card css={css_landing_card} grow>
            <CardContent title="View/search all participants">
              <Button onClick={() => props.history.push(`/participants`)}>
                Go
              </Button>
            </CardContent>
          </Card>
        </Column>
        <Column grow css={css_system_info}>
          <Card css={[css_landing_card, css_system_info_card]}>
            <CardContent title="System information" />
          </Card>
        </Column>
      </Row>
    </Column>
  );
}

function CardContent(props) {
  return (
    <Column grow>
      <Row css={css_h2}>{props.title}</Row>
      <Column grow center>
        {props.children}
      </Column>
    </Column>
  );
}

export default withRouter(Landing);
