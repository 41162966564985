/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Row } from "../../common/Flex.js";
import Input from "../../common/Input.js";

const css_select = css`
  padding: 10px;
  width: 149px;
`;

const css_label = css`
  padding-right: 10px;
`;

const css_info_container = css`
  padding: 10px;
`;
export default function ParticipantInfoRow({
  label = "...",
  type = "text",
  required = false,
  k,
  participant,
  onChange,
}) {
  if (!participant) {
    return <div />;
  }
  let value;
  if (label === "Program Name") {
    value = "VTCEI Veteran-Specific Screener";
  }
  let Component = (
    <Input
      value={value || (k && participant[k])}
      onChange={onChange}
      type={type}
    />
  );

  if (type === "state") {
    Component = (
      <select
        css={css_select}
        name="state-dropdown"
        value={k && participant[k]}
        onChange={onChange}
      >
        <option value="" disabled>
          Select State
        </option>
        {states().map((state) => (
          <option value={state} key={state}>
            {state}
          </option>
        ))}
      </select>
    );
  }

  return (
    <Row center css={css_info_container}>
      <span css={css_label}>
        {label}
        {required && "*"}
      </span>

      {Component}
    </Row>
  );
}

function states() {
  return [
    "AK",
    "AL",
    "AR",
    "AZ",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "HI",
    "IA",
    "ID",
    "IL",
    "IN",
    "KS",
    "KY",
    "LA",
    "MA",
    "MD",
    "ME",
    "MI",
    "MN",
    "MO",
    "MS",
    "MT",
    "NE",
    "NH",
    "NV",
    "NC",
    "ND",
    "NJ",
    "NM",
    "NY",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
  ];
}
