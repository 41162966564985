import React from 'react';

import './App.css';
import 'flatpickr/dist/themes/light.css';

import Header from './Header.js';
import Body from './Body.js';
import Footer from './Footer.js';

import { BrowserRouter as Router } from 'react-router-dom';

import { ParticipantProvider } from './contexts/ParticipantContext.js';
import { AssessmentProvider } from './contexts/AssessmentContext.js';
import { IdentityProvider } from './contexts/IdentityContext.js';
import { AnswerProvider } from './contexts/AnswersContext.js';
function App() {
	return (
		<div className="App">
			<Router>
				<IdentityProvider>
					<ParticipantProvider>
						<AnswerProvider>
							<AssessmentProvider>
								<Header />
								<Body />
								<Footer />
							</AssessmentProvider>
						</AnswerProvider>
					</ParticipantProvider>
				</IdentityProvider>
			</Router>
		</div>
	);
}

export default App;
