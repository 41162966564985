import React from "react";
import XLSX from "xlsx";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { withAnswer } from "./AnswersContext";

export const AssessmentContext = React.createContext();

let mapping = {
  A: "Section #",
  B: "Section Title",
  C: "Sub Section",
  D: "include in short section",
  E: "optional",
  F: "Same Page",
  G: "Question Number",
  H: "Answer Type",
  I: "Text",
  J: "Skip logic",
  K: "Options",
};

mapping = { ...mapping, ...swapKeyAndVal(mapping) }; //add reverse dictionary for easy reverse lookups

export const AssessmentProvider = withAnswer(
  withRouter(
    class extends React.Component {
      state = {
        doc: "",
        docs: [],
        isDocLoaded: false,
        current_question_index: 2, // 2 indexed
        current_question_number: "A1",
        current_section_index: 0,
        getCurrentQuestion: this.getCurrentQuestion.bind(this),
        getCurrentSection: this.getCurrentSection.bind(this),
        isCurrentSectionLastSection:
          this.isCurrentSectionLastSection.bind(this),
        getAssessmentType: () => this.assessment_type,
        next: this.next.bind(this),
        back: this.back.bind(this),
        reset: this.reset.bind(this),
        goTo: this.goTo.bind(this),
        reducedSections: this.reducedSections.bind(this),
      };

      componentWillMount() {
        this.fetchXls();
      }

      render() {
        return (
          <AssessmentContext.Provider value={this.state}>
            {this.props.children}
          </AssessmentContext.Provider>
        );
      }

      fetchXls() {
        let api =
          process.env.NODE_ENV === "development"
            ? "https://test2.vtceitool.org/api"
            : "/api";
        // fetch(`${api}/questions`).then(res => res.json()).then(res => console.log(res));

        // v1_5
        // let assessmentFileId = `1JURL-9dAE8sXfsQJnlXUqvOPAUPIrIprE0NMFgeX-fs`;
        // v1_6
        let assessmentFileId = `19GBhN_hu4CWYWpsGZb81KzdfUHzgGiVmDsmnM_gNCck`;
        // v1_8
        assessmentFileId = "1_6r_9DXCJHJ39ct_VtUkhluv2Yx9XTjf380FH5VxPgk";

        let assessmentUrl = `https://content.googleapis.com/drive/v3/files/${assessmentFileId}/export?mimeType=application%2Fvnd.openxmlformats-officedocument.spreadsheetml.sheet&key=AIzaSyBZ1-WKkdiimirjvMRTeDSc3LMiiJ0SDLs`;
        fetch(assessmentUrl)
          .then((res) => res.arrayBuffer())
          .then((ab) => new Uint8Array(ab))
          .then(this.processXls.bind(this))
          .catch((err) => console.warn(err));

        // fetch(
        // 	process.env.NODE_ENV === 'development'
        // 		? '/v1_5.xlsx'
        // 		: '/v1_5.xlsx'
        // )
        // 	.then((res) => res.arrayBuffer())
        // 	.then((ab) => new Uint8Array(ab))
        // 	.then(this.processXls.bind(this))
        // 	.catch((err) => console.warn(err));
      }

      processXls(doc) {
        const workbook = XLSX.read(doc, { type: "array" });
        console.log(workbook);
        if (workbook) {
          let sheet = workbook.Sheets["Sheet1"];
          let short_sheet = workbook.Sheets["Sheet2"];

          sheet = new AssessmentSheet(sheet);
          short_sheet = new AssessmentSheet(short_sheet);
          this.setState({
            docs: { long: sheet, short: short_sheet },

            isDocLoaded: true,
          });
        }
      }

      getCurrentQuestion() {
        return this.state.docs[this.assessment_type].getQuestion(
          this.state.current_question_index
        );
      }

      reducedSections() {
        return this.state.docs[this.assessment_type].sectionGroups.reduce(
          (accum, s) => {
            if (!s.subSections) {
              accum.push(s);
            } else {
              accum = [...accum, ...s.subSections];
            }
            return accum;
          },
          []
        );
      }
      getCurrentSection() {
        let sections = this.reducedSections();

        return sections[this.state.current_section_index];
      }

      isCurrentSectionLastSection(is_short) {
        let sections = this.normalizedSections();

        if (is_short) {
          sections = _.filter(sections, (s) =>
            _.some(s.questions, (q) => {
              return q.isShortSection;
            })
          );
        }
        let current_section = this.getCurrentSection();
        let last_section = sections[sections.length - 1];
        if (
          current_section.start === last_section.start &&
          current_section.end === last_section.end
        ) {
          return true;
        }
        return false;
      }

      normalizedSections() {
        return this.state.docs[this.assessment_type].sectionGroups.reduce(
          (accum, s) => {
            if (!s.subSections) {
              accum.push(s);
            } else {
              accum = [...accum, ...s.subSections];
            }
            return accum;
          },
          []
        );
      }

      get assessment_type() {
        return { p: "short", f: "long", s: "short", l: "long" }[
          this.props.answer.assessment_type
        ];
      }

      next() {
        let sections = this.state.docs[
          this.assessment_type
        ].sectionGroups.reduce((accum, s) => {
          if (!s.subSections) {
            accum.push(s);
          } else {
            accum = [...accum, ...s.subSections];
          }
          return accum;
        }, []);
        if (sections.length === this.state.current_section_index + 1) {
          this.props.answer.finish();
        } else {
          this.setState(
            {
              current_section_index: this.state.current_section_index + 1,
            },
            () => {
              let current_section = sections[this.state.current_section_index];
              let is_short_section = _.some(
                current_section.questions,
                (q) => q.isShortSection
              );
            }
          );
        }
      }

      back() {
        if (this.state.current_section_index !== 0) {
          this.setState({
            current_section_index: this.state.current_section_index - 1,
          });
        }
      }

      reset() {
        this.setState({ current_section_index: 0 });
      }

      goTo(index) {
        this.setState({ current_section_index: index });
        console.log("go to", index);
      }
    }
  )
);

class AssessmentSheet {
  constructor(sheetJSON) {
    this.raw = sheetJSON;
    this.mapping = mapping;

    let [rowStore, columnStore] = Object.keys(sheetJSON).reduce(
      (accum, key) => {
        let [column, row] = this.splitCR(key);
        if (column === null && row === null) {
          return accum;
        }
        let rowStore = accum[0];
        let columnStore = accum[1];

        rowStore[row] = rowStore[row] || {};
        columnStore[column] = columnStore[column] || {};

        rowStore[row][column] = sheetJSON[key];
        columnStore[column][row] = sheetJSON[key];
        return accum;
      },
      [{}, {}]
    );

    this.columnStore = columnStore;
    this.rowStore = rowStore;

    this.getSections();

    this.sectionGroups = this.getSectionGroups();
  }

  splitCR(str) {
    let match = str.match(/(\D+)(\d+)/);
    if (match) {
      let [, c, r] = match;
      return [c, r];
    } else {
      return [null, null];
    }
  }

  getSections() {
    let sections = this.columnStore[this.mapping["Section Title"]];
    sections = { ...sections };
    delete sections[1];
    sections = Object.keys(sections).map((key) => sections[key].v);
    return sections;
  }

  getQuestion(index) {
    return new Question(this.rowStore[index], this.rowStore[1]);
  }

  getSectionGroups() {
    let sections = this.columnStore[this.mapping["Section Title"]];
    sections = { ...sections };
    let subSections = this.columnStore[this.mapping["Sub Section"]];

    subSections = { ...subSections };
    delete sections[1];
    delete subSections[1];

    let keys = Object.keys(sections);

    sections = keys.reduce((accum, rowIndex, iteratorIndex) => {
      accum[rowIndex] = {
        title: sections[rowIndex],
        start: Number(rowIndex),
        end:
          keys[iteratorIndex + 1] - 1 || Number(this.getLastQuestionRowIndex()),
      };
      return accum;
    }, {});

    groupSubSections();
    assignQuestionsToSection.apply(this);

    function groupSubSections() {
      Object.keys(subSections).forEach((subSectionRowIndex) => {
        Object.keys(sections).forEach((sectionRowIndex) => {
          let section = sections[sectionRowIndex];
          if (
            Number(subSectionRowIndex) >= Number(section.start) &&
            Number(subSectionRowIndex) < Number(section.end)
          ) {
            section.subSections = section.subSections || {};
            section.subSections[subSectionRowIndex] =
              subSections[subSectionRowIndex];
          }
        });
      });

      _.forEach(sections, (section, key) => {
        if (section.subSections) {
          let subSection = section.subSections;
          let keys = Object.keys(subSection).map((n) => Number(n));
          keys.forEach((subKey, index) => {
            subKey = String(subKey);
            subSection[subKey].start = Number(subKey);
            subSection[subKey].end = Number(keys[index + 1] - 1) || section.end;
          });
        }
      });
    }

    function assignQuestionsToSection() {
      _.forEach(sections, (section, key) => {
        if (!section.subSections) {
          let i = section.start;
          let end = section.end;
          section.questions = [];
          while (i <= end) {
            section.questions.push(
              new Question(this.rowStore[i], this.rowStore[1])
            );
            i++;
          }
        } else {
          _.forEach(section.subSections, (subSection) => {
            let i = subSection.start;
            let end = subSection.end;
            subSection.questions = [];
            subSection.title = section.title;
            subSection.subTitle = subSection.v;
            while (i <= end) {
              subSection.questions.push(
                new Question(this.rowStore[i], this.rowStore[1])
              );
              i++;
            }
          });
          section.subSections = _.values(section.subSections);
        }
      });
    }

    // console.log(sections, subSections);
    return _.values(sections);
  }

  getLastQuestionRowIndex() {
    let keys = Object.keys(this.rowStore);
    return keys[keys.length - 1];
  }
}

function swapKeyAndVal(obj) {
  return Object.keys(obj).reduce((accum, key) => {
    accum[obj[key]] = key;
    return accum;
  }, {});
}

class Question {
  constructor(xls_sheet_row, header_row) {
    this.raw = xls_sheet_row;
    this.headers = header_row;
    this.test = this.options;
    let score = _.filter(this.options, (opt, index) => index % 2 === 1);
    let options = _.filter(this.options, (opt, index) => index % 2 === 0);
    this.answer_options = options;
    this.isOptional = this.is_optional;
    this.scores = score;
    this.isShortSection =
      this.raw[mapping["include in short section"]].v === true;
  }

  get text() {
    return new XlsCell(this.raw[mapping.Text]).value;
  }

  get questionNumber() {
    return new XlsCell(this.raw[mapping["Question Number"]]).value;
  }

  get input_type() {
    return new XlsCell(this.raw[mapping["Answer Type"]]).value;
  }

  get is_optional() {
    return new XlsCell(this.raw[mapping["optional"]]).value;
  }

  get skip_logic() {
    if (new XlsCell(this.raw[mapping[`Skip logic`]]).value) {
      return new XlsCell(this.raw[mapping[`Skip logic`]]).value
        .trim()
        .replace(/\s*([!=&|])\s*/g, "$1");
    }
  }

  get options() {
    let options = [];
    Object.keys(this.raw).forEach((key) => {
      if (key >= mapping.Options || key.length > 1) {
        options.push(new XlsCell(this.raw[key]).value);
      }
    });
    return options;
  }
}

class XlsCell {
  constructor(obj) {
    this.raw = obj;
  }

  get value() {
    if (!this.raw) {
      return undefined;
    }
    return this.raw.v;
  }
}
