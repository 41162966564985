/** @jsx jsx */
import { jsx, css } from '@emotion/core';

const types = {
	table: css``,
	normal: css`
		&.white {
			color: #ffffff;
		}
		&.h2 {
			font-size: 1.25em;
			padding-bottom: 2px;
		}
		&.h1 {
			font-size: 1.75rem;
			font-weight: bold;
		}
	`
};

export default function Label({
	type = 'normal',
	className,
	children,
	white,
	style
}) {
	return (
		<span
			css={types.normal}
			style={style}
			className={[className, white && 'white', type].join(' ')}
		>
			{children}
		</span>
	);
}

export function H1(props) {
	return <Label {...props} type="h1" />;
}

export function H2(props) {
	return <Label {...props} type="h2" />;
}
