/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Row } from './common/Flex.js';
import { H1, H2 } from './common/Label';
import { useHistory } from 'react-router-dom';

import HeaderNav from './Header/HeaderNav.js';

const css_header = css`
	height: 70px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 10px 25px;
	box-sizing: border-box;
	-webkit-box-shadow: 0px 3px 12px 8px rgba(30, 0, 255, 0.05);
	-moz-box-shadow: 0px 3px 12px 8px rgba(30, 0, 255, 0.05);
	box-shadow: 0px 3px 12px 8px rgba(30, 0, 255, 0.05);
	background: #1d4782;
	color: white;
	z-index: 1;
	& a {
		color: white;
	}
`;

const css_logo = css`
	cursor: pointer;
`;

function Header(props) {
	let History = useHistory();
	return (
		<Row css={css_header} spaceBetween center>
			<Row css={css_logo} onClick={() => History.push('/')} end>
				<H1>VTCEI</H1>

				<H2 style={{ paddingLeft: '5px' }}>
					Veteran-Specific Screener
				</H2>
			</Row>
			<HeaderNav />
		</Row>
	);
}

export default Header;
