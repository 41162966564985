/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Row } from './common/Flex.js';
import { Route, Switch } from 'react-router-dom';

import Landing from './Body/Landing.js';
import ParticipantList from './Body/ParticipantList.js';
import ParticipantOverview from './Body/ParticipantOverview.js';
import ParticipantEditor from './Body/ParticipantEditor';
import ParticipantCrreator from './Body/ParticipantCreator.js';
import Assessment from './Body/Assessment';
import Login from './Body/Login.js';
import Result from './Body/Result.js';
import ResetPassword from './Body/ResetPassword';
import ConfirmPassword from './Body/ConfirmPassword';
import Help from './Body/Help.js';
const css_body = css`
	min-height: calc(100vh - 130px);
	margin-top: 70px;
	background: var(--dark-blue-gradient);
`;

function Body(props) {
	return (
		<Row css={css_body}>
			<Switch>
				<Route path={`/login`} exact>
					<Login />
				</Route>
				<Route path={`/reset`} exact>
					<ResetPassword />
				</Route>
				<Route path={`/reset-password.php`} exact>
					<ConfirmPassword />
				</Route>
				<Route path={`/participants`} exact>
					<ParticipantList />
				</Route>
				<Route path={`/participants/new`} exact>
					<ParticipantCrreator />
				</Route>
				<Route path={`/participants/:id`} exact>
					<ParticipantOverview />
				</Route>
				<Route path={`/participants/:id/edit`} exact>
					<ParticipantEditor />
				</Route>
				<Route path={`/assessment/:participant_id`} exact>
					<Assessment />
				</Route>
				<Route path={`/score/:assessment_id`} exact>
					<Result />
				</Route>
				<Route path="/help"><Help/></Route>
				<Route path={`/`}>
					<Landing />
				</Route>
			</Switch>
		</Row>
	);
}

export default Body;
