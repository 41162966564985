/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Column, Row } from "../common/Flex.js";
import { useContext, useEffect, useState } from "react";

import Button from "../common/Button.js";

import Table from "../common/Table.js";
import ParticipantContext from "../contexts/ParticipantContext.js";
import { AnswerContext } from "../contexts/AnswersContext";
import { AssessmentContext } from "../contexts/AssessmentContext";
import LoadingSpinner from "../common/LoadingSpinner.js";
import { useParams, useHistory, Link } from "react-router-dom";
import _ from "lodash";

const css_title = css`
  color: white;
  padding: 20px 20px 20px 0;
  margin: 0;
  font-family: "Noto Sans", sans-serif;
`;

const css_subtitle = css`
  margin: 0px;
  color: white;
  font-family: "Noto Sans", sans-serif;
`;

const css_panel = css`
  animation: fade-in 0.5s;
`;

export default function ParticipantOverview(props) {
  const [state, setState] = useState({ participant: {} });
  let Params = useParams();
  let Participants = useContext(ParticipantContext);
  let History = useHistory();
  let Answers = useContext(AnswerContext);
  let Assessment = useContext(AssessmentContext);
  useEffect(() => {
    fetchParticipant();

    async function fetchParticipant() {
      let result = await Participants.dispatch({
        type: "FETCH_PARTICIPANT",
        uid: Params.id,
      });

      if (result.json) {
        try {
          result = await result.json();
          setState({ participant: result.data });
        } catch (err) {
          console.log(err);
        }
      }
    }
  }, [Params.id, Participants]);

  const case_columns = [
    {
      label: "Case Number",
      accessor: (d) => {
        return d.case_number;
      },
    },
    {
      label: "Arrest Date",
      accessor: (d) => {
        if (isNaN(new Date(d.arrest_date).valueOf())) {
          return null;
        }
        return d.arrest_date && new Date(d.arrest_date).toLocaleDateString();
      },
    },
    { label: "Arrest Charge", accessor: (d) => d.arrest_charge },

    { label: "Interviewer", accessor: (d) => d.interviewer_initials },
    {
      label: "Assessments",
      accessor: (d) => {
        let date_regex = /(\d{4})-(\d{2})-(\d{2})\s(\d{2}):(\d{2}):(\d{2})/;

        return (
          <Column>
            {d.assessments.map((a) => (
              <Link
                to={`/assessment/${_.get(state, "participant.user.uid", "NA")}`}
                key={a.assessment_id}
                onClick={() => {
                  Assessment.reset();

                  Answers.setActiveCase(d.case_id);
                  Answers.setActiveAssessment(
                    a.assessment_id,
                    { Partial: "p", Full: "f" }[a.type]
                  );
                }}
              >
                {deltaDate(a.date_started).toLocaleString()}{" "}
                {(() => {
                  console.log("DATE", a.date_started);
                })()}
                ({{ Partial: "S", Full: "C" }[a.type]})
              </Link>
            ))}
          </Column>
        );
      },
    },
    {
      label: "Actions",
      accessor: (d) => (
        <Column>
          <Link
            to={`/assessment/${_.get(state, "participant.user.uid", "NA")}`}
            onClick={() => {
              Answers.setActiveCase(d.case_id);
              Answers.createNewAssessment(
                d.case_id,
                "p",
                _.get(state, "participant.user.uid", "NA")
              );
              Assessment.reset();
            }}
          >
            New Assessment (Short)
          </Link>
          <Link
            to={`/assessment/${_.get(state, "participant.user.uid", "NA")}`}
            onClick={() => {
              Answers.setActiveCase(d.case_id);
              Answers.createNewAssessment(
                d.case_id,
                "f",
                _.get(state, "participant.user.uid", "NA")
              );
              Assessment.reset();
            }}
          >
            New Assessment (Comp.)
          </Link>
        </Column>
      ),
    },
  ];
  let participant = state.participant.user;
  if (!_.get(participant, "first_name")) {
    return (
      <Column center style={{ width: "100%" }}>
        <LoadingSpinner size={75} />
      </Column>
    );
  }
  return (
    <Column grow style={{ justifyContent: "start" }} center css={css_panel}>
      <Column>
        <Row center spaceBetween>
          <Column left>
            <h1 css={css_title}>Participant {_.get(participant, "uid")}</h1>
            <h3 css={css_subtitle}>
              {_.get(participant, "first_name")}{" "}
              {_.get(participant, "last_name")}
            </h3>
            <h3 css={css_subtitle}>
              DOB: {_.get(participant, "date_of_birth")}
            </h3>
            <h3 css={css_subtitle}>
              State ID#: {_.get(participant, "state_identifier")}
            </h3>
          </Column>
          <Row>
            <Button
              onClick={() => {
                History.push(
                  `/assessment/${_.get(state, "participant.user.uid", "NA")}`
                );

                Answers.createNewCase(Params.id, "p");
                Assessment.reset();
              }}
              style={{ marginRight: "20px" }}
            >
              <Column>
                <span>New Case</span>
                <span>(Short)</span>
              </Column>
            </Button>
            <Button
              onClick={() => {
                History.push(
                  `/assessment/${_.get(state, "participant.user.uid", "NA")}`
                );

                Answers.createNewCase(Params.id, "f");
                Assessment.reset();
              }}
            >
              New Case (Comprehensive)
            </Button>
          </Row>
        </Row>
        <Table
          columns={case_columns}
          data={state.participant.cases}
          placeholder="This participant has no cases."
        />
      </Column>
    </Column>
  );
}

function deltaDate(input, days, months, years) {
  console.log(input + "+00:00");
  input = new Date(input + "+00:00");
  return input;
  return new Date(
    input.getFullYear() + years,
    input.getMonth() + months,
    Math.min(
      input.getDate() + days,
      new Date(
        input.getFullYear() + years,
        input.getMonth() + months + 1,
        0
      ).getDate()
    ),
    input.getHours(),
    input.getMinutes(),
    input.getSeconds()
  );
}
