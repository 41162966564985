/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useContext } from "react";
import { Column } from "../../common/Flex.js";
import PageTitle from "../../common/PageTitle.js";
import { AssessmentContext } from "../../contexts/AssessmentContext.js";
import { AnswerContext } from "../../contexts/AnswersContext";
import _ from "lodash";

const css_panel = css`
  padding: 60px;
  color: white;
  min-width: 300px;
  animation: fade-in 0.5s;
  text-align: start;
`;

const css_li = css`
  text-decoration: underline;
  cursor: pointer;
  position: relative;
`;

const css_li_active_marker = css`
  font-size: 1.25rem;
  font-weight: bold;
  position: absolute;
  top: -4px;
  left: -30px;
  right: 0;
  bottom: 0;
`;

const space_right = css`
  padding-right: 5px;
`;

function hasScore(question) {
  return _.some(question.scores, (s) => s !== 0) ? 1 : 0;
}

function getTotalRequiredQuestions(questions, is_short_assessment) {
  return _.sum(
    questions.map((q) => {
      if (q.skip_logic || q.isOptional) {
        return 0;
      } else {
        return 1;
      }
    })
  );
}
function getTotalAnswered(questions, answers) {
  let answered_questions = Object.keys(answers);

  return _.sum(
    questions.map((q) => {
      if (q.skip_logic || q.isOptional) {
        return 0;
      }
      return answered_questions.includes(q.questionNumber) ? 1 : 0;
    })
  );
}

export default function SidePanel(props) {
  let i = 0;
  const Assessment = useContext(AssessmentContext);
  const Answers = useContext(AnswerContext);
  let type = { p: "Short", f: "Comprehensive" }[Answers.assessment_type];
  return (
    <Column css={css_panel}>
      <PageTitle>{type || "-"} Assessment</PageTitle>
      <Column>
        <span css={space_right}>Case ID: {Answers.activeCase || "..."}</span>
        <span>Assessment ID: {Answers.activeAssessment || "..."}</span>
      </Column>
      <ol>
        {Assessment.docs[Assessment.getAssessmentType()].sectionGroups.map(
          (section) => {
            let totals = 0;
            let answeredTotals = 0;
            if (section.questions) {
              totals = getTotalRequiredQuestions(section.questions, false);

              answeredTotals = getTotalAnswered(
                section.questions,
                Answers.answers
              );
            }
            let items = [
              <li
                css={css_li}
                key={section.title.v}
                onClick={(
                  (j) => () =>
                    Assessment.goTo(j)
                )(i)}
              >
                {!section.subSections && (
                  <SectionMarker
                    active={i === Assessment.current_section_index}
                  />
                )}
                <Column>
                  {section.title.v}
                  {totals > 0 && (
                    <span
                      style={{
                        fontWeight:
                          answeredTotals === totals ? "normal" : "bold",
                      }}
                    >
                      ({answeredTotals} out of {totals} required)
                    </span>
                  )}
                </Column>
              </li>,
            ];
            if (section.subSections) {
              let subList = section.subSections.map((subSection) => {
                i++;
                let assessment_is_short = Answers.assessment_type === "p";
                let is_short_section = _.some(
                  subSection.questions,
                  (q) => q.isShortSection
                );

                let is_long_only = !is_short_section;
                if (is_long_only && assessment_is_short) {
                  return null;
                }

                let totals = getTotalRequiredQuestions(
                  subSection.questions,
                  assessment_is_short
                );

                let answeredTotals = getTotalAnswered(
                  subSection.questions,
                  Answers.answers
                );

                return (
                  <li
                    css={css_li}
                    key={i}
                    onClick={(
                      (j) => () =>
                        Assessment.goTo(j)
                    )(i - 1)}
                  >
                    <SectionMarker
                      active={i - 1 === Assessment.current_section_index}
                    />
                    <Column>
                      {subSection.v}
                      <span
                        style={{
                          fontWeight:
                            answeredTotals === totals ? "normal" : "bold",
                        }}
                      >
                        ({answeredTotals} out of {totals} required)
                      </span>
                    </Column>
                  </li>
                );
              });
              items.push(
                <ul key={`subsections-${section.title.v}`}>{subList}</ul>
              );
            } else {
              i++;
            }

            return <Column>{items}</Column>;
          }
        )}
      </ol>
    </Column>
  );
}

function SectionMarker({ active }) {
  if (!active) {
    return null;
  }
  return <span css={css_li_active_marker}>{"\u203A\u203A"}</span>;
}
