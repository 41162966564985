/** @jsx jsx */
import { jsx, css } from '@emotion/core';

const css_title = css`
	color: white;
	margin: 0;
`;

export default function PageTitle(props) {
	return <h1 css={css_title}>{props.children}</h1>;
}
