/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import { useContext, useState } from "react";
import { Column, Row } from "../../common/Flex.js";
import { AssessmentContext } from "../../contexts/AssessmentContext.js";
import { AnswerContext } from "../../contexts/AnswersContext";
import { useHistory, useParams } from "react-router-dom";
import ParticipantContext from "../../contexts/ParticipantContext.js";
import Button from "../../common/Button";
import Input from "../../common/Input.js";

import _ from "lodash";

const css_panel = css`
  background: white;
  padding: 60px;
  animation: fade-in 0.5s;
  text-align: start;
`;
const padded_row = css`
  padding: 20px 0px;
`;

const intro_border = css`
  padding: 10px 0px;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
`;

export default function QuestionPanel(props) {
  const Assessment = useContext(AssessmentContext);
  const Answers = useContext(AnswerContext);
  const History = useHistory();
  let [is_save_error, setSaveError] = useState(false);

  let current_section = Assessment.getCurrentSection();

  let is_last_section = Assessment.isCurrentSectionLastSection(
    Answers.assessment_type === "p"
  );
  let required = getRequired(
    Assessment.docs[Assessment.getAssessmentType()].sectionGroups,
    Answers.assessment_type
  );

  let unanswered_remaining = getUnansweredRemaining(required, Answers.answers);
  let required_answers =
    Answers.answers.A1 &&
    Answers.answers.A3 &&
    Answers.answers.A5 &&
    Answers.answers.A6;
  let save_error;
  if (is_save_error) {
    if (!required_answers) {
      save_error = (
        <span>
          Could not save because answers to required questions (A1, A3, A5, A6)
          were missing.
        </span>
      );
    }
  }

  let missing_answers = _.compact([
    !Answers.answers.A1 && "A1",
    !Answers.answers.A3 && "A3",
    !Answers.answers.A5 && "A5",
    !Answers.answers.A6 && "A6",
  ]);
  return (
    <Column grow css={css_panel}>
      <Column>
        <h1>{current_section.title.v}</h1>
        {current_section.subTitle && <h2>{current_section.subTitle}</h2>}
        {current_section.questions.map((q) => (
          <Question q={q} key={q.text} />
        ))}
      </Column>
      {is_last_section && unanswered_remaining.length > 0 && (
        <Column style={{ color: "#DD4444" }}>
          <span>
            Some required questions are still missing, please go back and verify
            these before continuing:
          </span>
          <ul>
            {unanswered_remaining.map((item) => (
              <li key={item.questionNumber}>{item.questionNumber}</li>
            ))}
          </ul>
        </Column>
      )}
      {save_error}
      <Row css={padded_row} spaceBetween>
        <Button
          onClick={() => {
            Assessment.back();
            Answers.save(Assessment.save);
            setSaveError(false);
          }}
          style={{ width: "200px", margin: "0px 20px" }}
          disabled={Assessment.current_section_index === 0}
        >
          Back
        </Button>

        <Row>
          <Button
            style={{ width: "200px", margin: "0px 20px" }}
            onClick={async () => {
              if (!required_answers) {
                Assessment.reset();

                History.push("/cases");
              } else {
                let success = await Answers.save();
                if (success) {
                  Assessment.reset();
                  History.push("/cases");
                  setSaveError(false);
                } else {
                  setSaveError(true);
                }
              }
            }}
          >
            {!required_answers ? "Exit without saving" : "Save and exit"}
          </Button>
          <Button
            style={{ width: "200px", margin: "0px 20px" }}
            onClick={async () => {
              let success = await Answers.save();
              Assessment.next();
              window.scrollTo(0, 0);
              if (success) {
                setSaveError(false);
              }
            }}
            disabled={
              (is_last_section && unanswered_remaining.length > 0) ||
              !required_answers
            }
          >
            {is_last_section ? "Finish" : "Next"}
          </Button>
        </Row>
      </Row>
      {!required_answers && (
        <Column>
          <span>
            Note: in order to save and or complete this assessment, these
            missing answers must be completed:
          </span>
          <ul>
            {missing_answers.map((answer) => (
              <li>{answer}</li>
            ))}
          </ul>
        </Column>
      )}
    </Column>
  );
}

function getRequired(sectionGroups, type) {
  function addQuestion(q) {
    if (type === "p" && !q.isShortSection) {
      return null;
    }

    if (q.skip_logic) {
      return null;
    }

    // if (_.some(q.scores, (s) => s !== 0)) {
    // 	return q;
    // }
    if (q.isOptional === "FALSE") {
      return q;
    }
  }

  let groups = sectionGroups.reduce((accum, section) => {
    let questions = [];

    if (section.questions) {
      questions = section.questions.map(addQuestion);
    } else {
      questions = section.subSections.reduce((accum, sub) => {
        return [...accum, ...sub.questions.map(addQuestion)];
      }, []);
    }
    return [...accum, ...questions];
  }, []);
  return _.compact(groups);
}

function getUnansweredRemaining(remaining_list, answers) {
  let answer_keys = Object.keys(answers);
  return _.compact(
    remaining_list.map((q) =>
      answer_keys.includes(q.questionNumber) ? null : q
    )
  );
}

function Radio({ children, active, onClick }) {
  return (
    <Row style={{ padding: "8px", cursor: "pointer" }} onClick={onClick}>
      <input type="radio" checked={active} readOnly />
      <span style={{ paddingLeft: "5px" }}>{children}</span>
    </Row>
  );
}

function Question({ q }) {
  const Answers = useContext(AnswerContext);
  const Participants = useContext(ParticipantContext);
  const Params = useParams();
  let participant = Participants.get_participant(Params.participant_id) || "";
  if (Answers.assessment_type === "p" && !q.isShortSection) {
    return <span />;
  }
  let value = "";
  let current_answer = Answers.answers[q.questionNumber] || "";
  if (q.questionNumber === "A2") {
    value = `${participant.first_name || ""} ${participant.last_name || ""}`;
  }
  // TODO A3 fillin
  if (q.questionNumber === "A3") {
    value = participant.state_identifier;
  }
  if (q.questionNumber === "A4") {
    value = participant.date_of_birth;
  }

  let shouldSkip = q.skip_logic ? evaluateLogic(q.skip_logic) : false;

  if (shouldSkip) {
    return <div></div>;
  }

  function evaluateLogic(statement) {
    let answers = Answers.answers;
    if (statement.includes("||")) {
      let subStatements = statement
        .split("||")
        .map((subStatement) => evaluateLogic(subStatement));
      return subStatements.filter((s) => s).length > 0;
    } else if (statement.includes("&&")) {
      let subStatements = statement
        .split("&&")
        .map((subStatement) => evaluateLogic(subStatement));
      return subStatements.filter((s) => s).length === subStatements.length;
    } else {
      if (statement.includes("!=")) {
        let [questionNumber, value] = statement.split("!=");
        if (!answers[questionNumber]) {
          return false;
        }
        return value !== answers[questionNumber].qanswer;
      }
      let [questionNumber, value] = statement.split("=");
      if (!answers[questionNumber]) {
        return false;
      }
      return value === answers[questionNumber].qanswer;
    }
  }

  console.log("DEBUG QUESTION CONTENT", q);
  return (
    <Column style={{ paddingBottom: "25px" }}>
      <b css={q.input_type === "intro" && intro_border} key={q.text}>
        {!q.isOptional && "*"} {q.questionNumber}
        {q.input_type === "intro" ? ":" : "."} {q.text}
      </b>
      {["text", "number", "date"].includes(q.input_type) && (
        <Input
          value={current_answer.qanswer || value}
          type={q.input_type}
          onChange={(e) =>
            Answers.updateAnswer(q.questionNumber, e.target.value)
          }
        />
      )}

      {q.input_type === "multiple-choice" && (
        <MultipleChoice
          options={q.answer_options}
          scores={q.scores}
          value={current_answer.qanswer || ""}
          onChange={(opt, score) =>
            Answers.updateAnswer(q.questionNumber, opt, score)
          }
        />
      )}
      {q.input_type === "multi-select" && (
        <MultiSelect
          options={q.answer_options}
          values={current_answer.qanswer || ""}
          onChange={(opt, score) => {
            Answers.updateAnswer(q.questionNumber, opt, score);
          }}
        />
      )}
    </Column>
  );
}

function MultipleChoice({ options = [], value, onChange, scores = [] }) {
  return (
    <Column left center>
      {options.map((option, index) => (
        <Radio
          active={option === value}
          key={option}
          onClick={() => onChange(option, scores[index])}
        >
          {option}
        </Radio>
      ))}
    </Column>
  );
}

function MultiSelect({ options, values = "", onChange }) {
  if (!Array.isArray(values)) {
    values = values.split("|\\|");
  }
  if (values[0] === "") {
    values = [];
  }
  return (
    <Column left center>
      {options.map((option) => (
        <Checkbox
          key={option}
          value={values.includes(option)}
          onChange={(e) => {
            if (values.includes(option)) {
              let newOptions = [...values];
              newOptions = _.filter(newOptions, (o) => o !== option);
              newOptions.join("|\\|");
              onChange(newOptions);
            } else {
              let newOptions = [...values, option];
              newOptions = newOptions.join("|\\|");
              onChange(newOptions);
            }
          }}
        >
          {option}
        </Checkbox>
      ))}
    </Column>
  );
}

function Checkbox({ children, onChange, value }) {
  return (
    <Row style={{ padding: "8px", cursor: "pointer" }} onClick={onChange}>
      <input type="checkbox" onChange={onChange} checked={value} />
      <span style={{ paddingLeft: "5px" }}>{children}</span>
    </Row>
  );
}
