/** @jsx jsx */
import { jsx, css } from '@emotion/core';

const loader = css`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const balls = css`
	width: 90px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const item = css`
	$anim-drt: 0.4s;
	$anim-ease: cubic-bezier(0.6, 0.05, 0.15, 0.95);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #00f1ca;

	&:nth-of-type(1) {
		animation: bouncing $anim-drt alternate infinite $anim-ease;
	}

	&:nth-of-type(2) {
		animation: bouncing $anim-drt $anim-drt/4 alternate infinite $anim-ease
			backwards;
	}

	&:nth-of-type(3) {
		animation: bouncing $anim-drt $anim-drt/2 alternate infinite $anim-ease
			backwards;
	}
`;

function Loader() {
	return (
		<div css={loader}>
			<div css={balls}>
				<div css={item} />
				<div css={item} />
				<div css={item} />
			</div>
		</div>
	);
}

export default Loader;
