/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useContext, useEffect } from 'react';
import { Column, Row } from '../common/Flex.js';
import Button from '../common/Button';
import { Link, useLocation } from 'react-router-dom';
import Input from '../common/Input';
import { IdentityContext } from '../contexts/IdentityContext.js';

const css_container = css`
	width: 100%;
	background-image: url('/steps.png');
	background-size: cover;
	position: relative;
`;

const css_shade_blue = css`
	background: #004fa2;
	opacity: 0.6;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
`;
export default function ResetScreen(props) {
	return (
		<Column center css={css_container}>
			<div css={css_shade_blue} />
			<ResetCard />
		</Column>
	);
}

const css_card = css`
	background: #ffffff;
	border: 2px solid #979797;
	box-shadow: 0px 0px 29px rgba(0, 0, 0, 0.32);
	border-radius: 6px;
	padding: 30px;
	z-index: 1;
`;

const css_h2 = css`
	font-size: 20px;
	font-weight: bold;
	padding: 0px;
	margin: 0px;
`;

const css_h1 = css`
	font-size: 48px;
	font-weight: bold;
	padding: 0px;
	margin: 0px;
`;

const css_input_container = css`
	padding-top: 48px;
`;

const css_extra_action_container = css`
	padding-top: 48px;
`;

const css_extra_action = css`
	&:first-of-type {
		padding-right: 10px;
	}

	&:last-of-type {
		padding-left: 10px;
	}
`;

const css_input = css`
	margin-bottom: 10px;
`;

const css_error_message = css`
	color: red;
`;

function ResetCard(props) {
	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	let Identity = useContext(IdentityContext);
	let Query = useQuery();

	useEffect(() => () => Identity.clearResetPassword, []);

	return (
		<Column center css={css_card}>
			<Column>
				<h2 css={css_h2}>
					Veterans Treatment Court Enhancement Initiative
				</h2>
				<h1 css={css_h1}>Reset Password</h1>
			</Column>
			<Column css={css_input_container}>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						Identity.requestReset();
					}}
				>
					{Identity.reset_error && (
						<div css={css_error_message}>
							An error occurred, could not reset password.
						</div>
					)}
					{Identity.reset_submitted && (
						<Column
							style={{ color: '#007f64' }}
							css={css_error_message}
						>
							<span>Reset request submitted.</span>
							<span>
								If an account with this email exists, you will
								receive an email.
							</span>
						</Column>
					)}
					<div css={css_input}>
						<Input
							placeholder="Court Staff ID"
							name="username"
							onChange={Identity.input}
							value={Identity.username}
						/>
					</div>

					<Button type="submit" onClick={Identity.requestReset}>
						Reset Password
					</Button>
				</form>
			</Column>
		</Column>
	);
}
