/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useContext, useState, useEffect } from "react";
import ParticipantContext from "../contexts/ParticipantContext";
import { Column, Row } from "../common/Flex.js";
import { useParams, useHistory } from "react-router-dom";
import Button from "../common/Button.js";
import { H1 } from "../common/Label.js";
import ParticipantInfoRow from "./ParticipantEditor/ParticipantInfoRow.js";

const css_container = css`
  width: 100%;
  justify-content: start;
  margin-top: 20px;
  padding: 0 80px 20px;
  color: white;
`;

const css_h1 = css`
  text-align: start;
`;

const css_button = css`
  margin: 20px 0px;
`;

const css_form_container = css`
  background: white;
  color: #333;
  padding: 20px 0px;
  background: #ffffff;
  border: 2px solid #979797;
  box-shadow: 0px 0px 29px rgba(0, 0, 0, 0.32);
  border-radius: 6px;
  overflow: auto;
`;

function ParticipantEditor(props) {
  const Params = useParams();
  const History = useHistory();
  const Participant = useContext(ParticipantContext);

  const participant_info = [
    {
      label: "First name",
      type: "text",
      required: true,
      key: "first_name",
    },
    { label: "Last name", type: "text", required: true, key: "last_name" },
    {
      label: "Date of birth",
      type: "date",
      required: false,
      key: "date_of_birth",
    },
    { label: "Address", type: "text", required: false, key: "address" },
    { label: "Address 2", type: "text", required: false, key: "address2" },
    { label: "City", type: "text", required: false, key: "city" },
    { label: "State", type: "state", required: false, key: "state" },
    { label: "Zip", type: "text", required: false, key: "zip" },
    {
      label: "State ID number",
      type: "text",
      required: false,
      key: "state_identifier",
    },

    // {
    // 	label: 'Case number',
    // 	type: 'text',
    // 	required: false,
    // 	key: 'case_number'
    // },
    // {
    // 	label: 'Arrest date',
    // 	type: 'date',
    // 	required: false,
    // 	key: 'arrest_date'
    // },
    // {
    // 	label: 'Arrest charge',
    // 	type: 'text',
    // 	required: false,
    // 	key: 'arrest_charge'
    // },
    // { label: "Charge severity", type: "text", required: false, key: "charge_severity" },
    // {
    // 	label: 'Program name',
    // 	type: 'text',
    // 	required: false,
    // 	key: 'program_name'
    // }
  ];

  const participant = Participant.get_participant(Params.id);
  useEffect(() => {
    fetchParticipant();
    async function fetchParticipant() {
      let result = await Participant.fetch_participant(Params.id);
      if (result.json) {
        result = await result.json();
        setState({
          participant: { uid: Params.id, ...result.data.user },
        });
      }
    }
  }, [Params.id, Participant]);

  const [state, setState] = useState({ participant });
  function validate() {
    console.log(state.participant);
  }

  return (
    <Column css={css_container} grow center>
      <Column style={{ width: "100%" }}>
        <Row spaceBetween grow>
          <H1 css={css_h1}>Edit Participant {Params.id}</H1>
          <Button
            css={css_button}
            onClick={async () => {
              try {
                validate();
                await Participant.dispatch({
                  type: "SAVE",
                  payload: state.participant,
                });

                History.push("/participants");
              } catch (e) {
                console.log(e);
              }
            }}
          >
            Save and Return
          </Button>
        </Row>

        <Column css={css_form_container} center>
          <Column end>
            {participant_info.map((p, index) => (
              <ParticipantInfoRow
                onChange={(e) =>
                  setState({
                    participant: {
                      ...state.participant,
                      [p.key]: e.target.value,
                    },
                  })
                }
                participant={state.participant}
                {...p}
                key={p.key || index}
                k={p.key}
              />
            ))}
          </Column>
        </Column>
      </Column>
    </Column>
  );
}

export default ParticipantEditor;
