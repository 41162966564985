import generateContext from './generateContext.js';
import { withRouter } from 'react-router-dom';
import Requisition from '../common/RequisitionLite.js';

let [IdentityContext, ProviderBase, withIdentity] = generateContext('identity');
let api =
	process.env.NODE_ENV === 'development' ? 'https://test2.vtceitool.org' : '';
console.log(process.env.NODE_ENV, api);
const IdentityProvider = withRouter(
	class extends ProviderBase {
		state = {
			username: '',
			password: '',
			new_password: '',
			confirm_password: '',
			exp_at: '',
			error: '',
			reset_submitted: false,
			reset_error: false,
			confirm_submitted: false,
			confirm_error: false,
			requestReset: this.requestReset.bind(this),
			input: this.input.bind(this),
			isLoggingIn: false,
			login: this.login.bind(this),
			logout: this.logout.bind(this),
			createNewPassword: this.createNewPassword.bind(this),
		};

		input(e) {
			if (e) {
				let field = e.target.name;
				let value = e.target.value;

				this.setState({ [field]: value });
			}
		}

		async createNewPassword(s, t) {
			let payload = {
				s,
				t,
				pwd: this.state.new_password,
				pwd2: this.state.confirm_password,
			};
			try {
				let result = await Requisition(`${api}/api/updatepwd`, {
					method: 'POST',
					body: JSON.stringify(payload),
				}).then((res) => res.json());

				if (result.error === 'Password successfully updated') {
					this.setState({ confirm_submitted: true });
				} else {
					throw new Error(
						result.error ||
						'There was a problem creating a new password'
					);
				}
			} catch (e) {
				this.setState({ confirm_error: e.message });
			}
		}

		componentDidMount() {
			this.load_exp();
			this.validateLoginState();
		}

		componentDidUpdate() {
			this.validateLoginState();
			// this.refresh();
		}

		componentWillUnmount() {
			this.stopRefreshCycle();
		}

		clearResetPassword() {
			this.setState({ reset_submitted: false, reset_error: false });
		}
		validateLoginState() {
			let expAt =
				this.state.exp_at || window.localStorage.getItem('exp_at');
			const triggerExpire = () =>
				this.props.history.push('/login?expired=true');
			const triggerNeverLogged = () => this.props.history.push('/login');
			if (
				!['/login', '/reset', '/reset-password.php'].includes(
					this.props.location.pathname
				) &&
				!expAt
			) {
				console.log(this.props.location.pathname, 'NOT GOOD');
				triggerNeverLogged();
			}
			if (!expAt) {
				this.stopRefreshCycle();
			}
			console.log(
				new Date(Number(expAt)).valueOf(),
				new Date().valueOf(),
				new Date(Number(expAt)).valueOf() < new Date().valueOf()
			);
			if (
				expAt &&
				new Date(Number(expAt)).valueOf() < new Date().valueOf()
			) {
				this.clear_exp();
				expAt = '';
				triggerExpire();
			}

			if (expAt && !this.refresh_cycle) {
				this.startRefreshCycle();
			}
		}

		async login() {
			//endpoint
			this.setState({ isLoggingIn: true, error: '' });
			let payload = {
				email: this.state.username,
				passwd: this.state.password,
			};
			let result = await Requisition(`${api}/api/login`, {
				method: 'POST',
				body: JSON.stringify(payload),
			}).then((res) => res.json());
			console.log(result);
			let exp_at = new Date(
				new Date().getTime() + 60 * 10 * 1000
			).getTime();
			this.setState({ isLoggingIn: false, exp_at });
			this.save_exp(exp_at);
			if (result.success) {
				this.props.history.push('/');
				this.startRefreshCycle();
			} else {
				this.setState({ error: result.error });
			}
		}

		async requestReset() {
			let payload = {
				email: this.state.username,
			};
			try {
				let result = await Requisition(`${api}/api/resetpwd`, {
					method: 'POST',
					body: JSON.stringify(payload),
				}).then((res) => res.json());
				this.setState({ reset_submitted: true });
			} catch (e) {
				this.setState({ reset_error: true });
			}
		}

		startRefreshCycle() {
			console.log('starting refresh cycle');

			this.refresh_cycle = setInterval(
				this.refresh.bind(this),
				1000 * 60 * 2
			);
		}

		async refresh() {
			console.log('refreshing');
			let result = await Requisition(`${api}/api/refresh`, {
				method: 'POST',
			});
			console.log(result);
			let exp_at = new Date(
				new Date().getTime() + 60 * 10 * 1000
			).getTime();
			this.setState({ exp_at });
			this.save_exp(exp_at);
		}

		stopRefreshCycle() {
			if (this.refresh_cycle) {
				console.log('stopping refresh cycle');
				clearInterval(this.refresh_cycle);
			}
		}

		logout() {
			Requisition(`${api}/api/logout`, {
				method: 'POST',
			});
			this.clear_exp();
		}

		clear_exp() {
			this.stopRefreshCycle();
			console.log('clearing exp_at');
			window.localStorage.removeItem('exp_at');
			this.setState({ exp_at: '' });
		}

		save_exp(exp_at) {
			console.log('setting exp at', exp_at);
			window.localStorage.setItem('exp_at', exp_at);
		}

		load_exp() {
			let exp_at = window.localStorage.getItem('exp_at');
			console.log('loading exp_at', exp_at);
			this.setState({ exp_at });
		}
	}
);

export { IdentityContext, IdentityProvider, withIdentity };
