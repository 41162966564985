export default function Handle403(res) {
	if (res.status === 403) {
		if (
			!['/login', '/reset-password.php', '/reset'].includes(
				window.location.pathname
			)
		) {
			console.log('rerouting');
			window.location = '/login?expired=true';
		}
	}
	return res;
}
