import React from 'react';
import _ from 'lodash';
import Requisition from '../common/RequisitionLite.js';
import generateContext from './generateContext.js';

const [ParticipantContext, , withParticipant] = generateContext('participant');
export default ParticipantContext;
let api =
	process.env.NODE_ENV === 'development'
		? 'https://test2.vtceitool.org/api'
		: '/api';
export { withParticipant };
export class ParticipantProvider extends React.Component {
	componentDidMount() {
		this.dispatch({ type: 'FETCH_PARTICIPANTS' });
	}

	setFilterDebounced = _.debounce(this.setFilterDebounced, 500);
	state = {
		participants: [],
		filter: '',
		debounced_filter: '',
		dispatch: this.dispatch.bind(this),
		get_participant: this.get_participant.bind(this),
		fetch_participant: this.fetch_participant.bind(this),
		isParticipantsLoaded: true,
	};

	render() {
		return (
			<ParticipantContext.Provider value={this.state}>
				{this.props.children}
			</ParticipantContext.Provider>
		);
	}

	dispatch({ type, ...action }) {
		switch (type) {
			case 'SET_FILTER':
				this.setState({ filter: action.value });
				this.setFilterDebounced(action.value);
				break;
			case 'FETCH_PARTICIPANTS':
				return this.fetch_participants();

			case 'FETCH_PARTICIPANT':
				return this.fetch_participant(action.uid);

			case 'SAVE':
				return this.save_participant(action.payload);

			case 'CREATE':
				return this.create_participant(action.payload);

			default:
				console.warn('Unhandled type', type);
				break;
		}
	}

	setFilterDebounced(value) {
		this.setState({ debounced_filter: value });
	}

	get_participants() {
		if (
			this.state.participants &&
			this.state.participants > 0 &&
			this.state.isParticipantsLoaded
		) {
			return this.state.participants;
		}
	}

	get_participant(uid) {
		if (this.state.participants) {
			for (let p in this.state.participants) {
				if (this.state.participants[p].uid === uid) {
					return this.state.participants[p];
				}
			}
		}
	}

	async fetch_participants() {
		return Requisition(`${api}/participant/all`)
			.then((res) => res.json && res.json())
			.then((res) => res.data)
			.then((res) => {
				this.setState({ participants: res });
				return res;
			})
			.catch((err) => console.warn(err));
	}

	async fetch_participant(uid) {
		return Requisition(`${api}/participant/${uid}`).catch((err) =>
			console.warn(err)
		);
	}

	async save_participant(payload) {
		console.log(payload);
		return Requisition(`${api}/participant/${payload.uid}`, {
			method: 'PUT',
			body: JSON.stringify(payload),
		});
	}

	async create_participant(payload) {
		payload.participant_court = 'a1b2c3';
		return Requisition(`${api}/participant/create`, {
			method: 'POST',
			body: JSON.stringify(payload),
		});
	}
}
