/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Row } from './common/Flex.js';

const css_footer = css`
	height: 40px;
	padding: 10px;
	background: #0f2846;
	border-top: 1px solid rgba(100, 100, 100, 0.1s);
	color: white;
`;

const css_h1 = css`
	font-size: 1.75em;
	font-weight: bold;
`;

const css_h2 = css`
	font-size: 1.25em;
	padding-bottom: 2px;
`;

const css_h3 = css`
	font-size: 1em;
`;

function Footer(props) {
	return (
		<Row center spaceBetween css={css_footer}>
			<Row end>
				<span css={css_h1}>VTCEI</span>
				<span style={{ paddingLeft: '5px' }} css={css_h2}>
					Veteran-Specific Screener
				</span>
			</Row>
			<span css={css_h3}>Ⓒ 2019 JPO, all rights reserved</span>
		</Row>
	);
}

export default Footer;
