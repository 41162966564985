/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Column } from './Flex.js';

const css_card = css`
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1);
	background: #fff;
	border-radius: 2px;
`;

function Card(props) {
	return <Column css={css_card} {...props} />;
}

export default Card;
