/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useContext } from 'react';
import { Row } from '../common/Flex.js';
import { Link } from 'react-router-dom';
import { IdentityContext } from '../contexts/IdentityContext.js';
const css_nav_link = css`
	padding: 0px 10px;
`;

function HeaderNav(props) {
	const Identity = useContext(IdentityContext);
	if (!Identity.exp_at) {
		return null;
	}
	return (
		<Row>
			<HeaderNavLink to={`/participants`}>Participants</HeaderNavLink>
			<HeaderNavLink to={`/cases`}>Cases</HeaderNavLink>
			<HeaderNavLink to={'/help'}>Help</HeaderNavLink>
			<HeaderNavLink to={`/login`} onClick={Identity.logout}>
				Logout
			</HeaderNavLink>
		</Row>
	);
}

function HeaderNavLink(props) {
	if (props.external) {
		return (
			<a href={props.to} css={css_nav_link}>
				{props.children}
			</a>
		);
	}

	return (
		<Link to={props.to} css={css_nav_link} onClick={props.onClick}>
			{props.children}
		</Link>
	);
}
export default HeaderNav;
