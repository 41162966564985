/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Row } from "./Flex";
const css_button = css`
	padding: 1rem 2rem;
	background-color: #ed193a;
	color: white;
	font-size: 1rem;
	font-weight: bold;
	width: 240px;
	border: 0px;
	border-radius: 3px;
	cursor: pointer;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
`;

const css_disabled = css`
	pointer-events: none;
	background-color: #b1959a;
`;
function Button(props) {
	return (
		<button
			tabIndex="1"
			type={props.type}
			className={props.className}
			css={[css_button, props.disabled && css_disabled]}
			style={props.style}
			onClick={props.onClick}
			onKeyPress={props.onKeyPress}
		>
			<Row center>{props.children}</Row>
		</button>
	);
}

export default Button;
